#duck {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: -20px;
    position: relative;
    top: 5px;
}

#duck > code {
    cursor: pointer;
}